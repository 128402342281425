<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nueva</span>
                    <span v-if="!isNew">Editar</span>
                    categoría de persona
                </div>

                <v-form
                    ref="form"
                    v-model="isValid"
                    autocomplete="off">
                <v-tabs v-model="tab" background-color="transparent" show-arrows>
                    <v-tabs-slider color="blue"></v-tabs-slider>

                    <v-tab href="#tab-percat">Categoría</v-tab>
                    <v-tab href="#tab-emp">Empresas</v-tab>
                    <v-tab href="#tab-per">Personas</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item :value="'tab-percat'">
                        <v-card style="background-color: #F5F5F5" flat>
                            <!-- FORMULARIO CATEGORIA -->
                            <v-row>
                                <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                                    <v-text-field
                                        class="mb-n7"
                                        outlined
                                        dense
                                        text
                                        v-model="perCat.PerCatCod"
                                        label="Código">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                          <v-row>
                              <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                                  <v-text-field
                                      class="mb-n7"
                                      outlined
                                      dense
                                      text
                                      required
                                      :rules="[v => !!v || 'El nombre es obligatorio']"
                                      v-model="perCat.PerCatNom"
                                      label="Nombre *">
                                  </v-text-field>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                                  <v-text-field
                                      class="mb-n7"
                                      outlined
                                      dense
                                      text
                                      :value="perCat.PerCatParent != null ? perCat.PerCatParent.PerCatNom : ''"
                                      tabindex="-1"
                                      readonly
                                      label="Tipo">
                                  </v-text-field>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                                  <PmsMsjFind class="mb-n7" ref="PmsMsjFindCtrl" v-model="perCat.PmsMsj" label="Permiso"></PmsMsjFind>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                                  <v-textarea
                                      class="mt-0 mb-n7"
                                      outlined
                                      auto-grow
                                      v-model="perCat.PerCatObs"
                                      label="Observaciones">
                                  </v-textarea>
                              </v-col>
                          </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'tab-emp'" eager>
                        <v-card style="background-color: #F5F5F5" flat>
                            <v-row class="mt-0">
                                <!-- EMPRESAS -->
                                <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                    <EmpresasRelacionadas
                                        ref="relatedEmps"
                                        :empList="empDataList" 
                                        :empTotal="empDataTotal" 
                                        :loading="loading" 
                                        @update-data="updateEmpData">
                                    </EmpresasRelacionadas>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'tab-per'">
                        <v-card style="background-color: #F5F5F5" flat>
                            <v-row class="mt-0">
                                <!-- PERSONAS -->
                                <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                    <!-- Botón añadir persona -->
                                    <v-btn
                                        :loading="loading"
                                        :disabled="editPer"
                                        @click="onBtnNuevaPer"
                                        color="primary"
                                        small
                                        class="mb-2">
                                        Añadir persona
                                    </v-btn>
                                  
                                    <v-data-table
                                        :headers="perHeaders"
                                        :items="perDataRows"
                                        :options.sync="perOptions"
                                        :footer-props="{itemsPerPageOptions: [15]}"
                                        :server-items-length="perTotalDataRows"
                                        :loading="perLoading"
                                        style="overflow: scroll"
                                        mobile-breakpoint="0"
                                        class="elevation-1"
                                        dense>
                                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                                        <template v-slot:item.Opt="{ item }">
                                            <v-btn
                                                color="error"
                                                class="mr-2 my-1"
                                                small
                                                @click.stop="onBtnShowPerDeleteModal(item)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <!-- NUEVA PERSONA FORMULARIO -->
                                        <template v-if="editPer" slot="body.append">
                                            <tr class="new-row">
                                                <td>
                                                    <PerFind class="mb-n7" ref="newPerCtrl" v-model="newPer" label="Persona" @change="validatePer"></PerFind>
                                                </td>
                                                <td style="min-width: 250px" align ="right">
                                                    <v-btn
                                                        :loading="perLoading"
                                                        :disabled="!isValidNewPer"
                                                        @click="onBtnSavePer"
                                                        color="primary"
                                                        class="mt-2 mb-4 mr-2"
                                                        small>
                                                        Aceptar
                                                    </v-btn>
                                                    
                                                    <v-btn
                                                        @click="onBtnCancelPer"
                                                        class="mt-2 mb-4 mr-2"
                                                        small>
                                                        Cancelar
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

                <!-- ACEPTAR / CANCELAR / BORRAR -->
                <v-row class="mt-2" justify="center">
                    <v-btn
                        :loading="loading"
                        :disabled="!isValid"
                        @click="onBtnSave"
                        color="primary"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                        Aceptar
                    </v-btn>
                    
                    <v-btn
                        class="mt-2 mb-4 mr-2"
                        style="width:100px"
                        @click="goToList">
                        Cancelar
                    </v-btn>

                    <!-- && canDelete" -->
                    <v-btn v-if="!isNew"
                        color="error"
                        class="mt-2 mb-4"
                        style="width:100px"
                        @click="onBtnShowDeleteModal">
                        Borrar
                    </v-btn>
                </v-row>
                </v-form>
            </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Categoria  -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR CATEGORIA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la categoría? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Borrar persona -->
        <v-dialog
            v-model="showPerDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR PERSONA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la persona?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="perLoading"
                    color="primary"
                    @click="onBtnPerDelete"
                  >
                    Aceptar
                  </v-btn>
                  <v-btn
                    @click="onBtnCancelPerDelete"
                  >
                    Cancelar
                  </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import EmpresasRelacionadas from "../components/EmpresasRelacionadas.vue";
import PerFind from "../components/PerFind.vue";
import PmsMsjFind from "../components/PmsMsjFind.vue";
export default ({
    components: {
        EmpresasRelacionadas, PerFind, PmsMsjFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            // canDelete: false,
            showDeleteDialog: false,
            showPerDeleteDialog: false,
            perCat: {
                PerCatId: 0,
                PerCatCod: '',
                PerCatNom: '',
                PerCatObs: '',
                PerCatParent: null,
                PerCatIdParent: null,
                PmsMsj: {}
            },
            // Datos tabla empresas
            empDataTotal: 0,
            empDataList: [],
            // Tabla personas
            perLoading: false,
            perTotalDataRows: 0,
            perDataRows: [],
            perOptions: {page: 1, itemsPerPage: 15},
            perHeaders: [
                { text: 'Personas relacionadas', width: 325, sortable: false, value: 'PerNom' },
                { text: '', width: 100, sortable: false, value: 'Opt', align: 'end' }
            ],
            perOptionsLoaded: false,
            // Edición personas
            editPer: false, // Indica si se esta editando la nueva empresa
            newPer: {},
            isValidNewPer: false,
            selectedPer: null, // Indica la empresa seleccionada para el borrado
            addedPers: [],
            deletedPers: []
        }
    },
    watch: {
      perOptions: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.perOptionsLoaded) {
            this.perOptionsLoaded = true;
            return;
          }
          this.loadRelatedPers();
        },
        deep: true
      },
      // perCat: {
      //   handler () {
      //       this.validate();
      //   },
      //   deep: true,
      // },
    },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.perCat.PerCatId = this.$route.params.id;
        this.loadData();
        this.loadRelatedPers();
      } else {
        // Init PerCatParent
        if(this.$store.state.PerCatParentData != null) {
          this.perCat.PerCatParent = this.$store.state.PerCatParentData;
          this.perCat.PerCatIdParent = this.$store.state.PerCatParentData.PerCatId;
        }

        // Usar eager en la v-tab-item para poder tener la ref cargada
        this.$refs.relatedEmps.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/categorias-persona');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_PERCAT',
            PerCatId: this.perCat.PerCatId,
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/percat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.PerCat;
            // const canDelete = result.data.CanDelete;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item,
                  // canDelete
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la categoría.');
                this.goToList();
            }
          });
        })
      },
      perCatHasPerFromApi(perId) {
        this.perLoading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'PERCAT_HAS_PER',
            PerCatId: this.perCat.PerCatId,
            PerId: perId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/percat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const any = result.data;

            setTimeout(() => {
                this.perLoading = false;
                resolve({
                  any
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.perLoading = false;
                this.isValidNewPer = false;
            }
          });
        })
      },
      getRelatedPerListFromApi() {
        this.perLoading = true;

        var NumRegsPag = this.perOptions.itemsPerPage;
        var NumPag = this.perOptions.page;
        
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_CATEGORY_RELATED_PER_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            PerCatId: this.perCat.PerCatId,
            EmpId: this.empId,
            AddedPerIds: this.addedPers,
            DeletedPerIds: this.deletedPers
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.perLoading = false
                resolve({
                  items,
                  total
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.perLoading = false;
                alert('Error al cargar las personas.');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        this.perCat.PmsMsjId = this.perCat.PmsMsj != null ? this.perCat.PmsMsj.PmsMsjId : null;
        const empIds = this.empDataList.map(m => m.EmpId);
        
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            PerCat: this.perCat,
            EmpIds: empIds,
            AddedPerIds: this.addedPers,
            DeletedPerIds: this.deletedPers
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/percat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar la categoría.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            PerCatId: this.perCat.PerCatId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/percat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar la categoría.');
            }
          });
        })
      },
      //#endregion
      
      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Categoría no encontrada.');
              this.goToList();
              return;
            } 

            this.perCat = data.item;
            // this.canDelete = data.canDelete;
            
            // Cargamos los datos de empresas
            this.empDataTotal = data.item.PerCatEmp.length;
            this.empDataList = [];
            data.item.PerCatEmp.forEach(i => {
              this.empDataList.push({
                EmpId: i.Emp.EmpId,
                EmpNom: i.Emp.EmpNom
              });
            });
          });
      },
      loadRelatedPers() {
        this.getRelatedPerListFromApi()
        .then(data => {
          this.perDataRows = data.items
          this.perTotalDataRows = data.total
        });
      },
      //#endregion

      //#region Añadir personas
      onBtnNuevaPer() {
        this.editPer = true;
        this.newPer = null;
        this.validatePer();
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.PerCatId != null) {
                this.goToList();
            }
            else {
              alert('Error al guardar la categoría.');
            }
          });
      },
      onBtnSavePer() {
        if(this.newPer == null) { return; }
        const index = this.deletedPers.indexOf(this.newPer.PerId);
        if(index == -1) {
          this.addedPers.push(this.newPer.PerId);
        } else {
          this.deletedPers.splice(index, 1);
        }
        this.newPer = null,
        this.editPer = false;
        this.loadRelatedPers();
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region Cancelar creación de persona
      onBtnCancelPer() {
        this.editPer = false;
      },
      //#endregion

      //#region Eliminar personas
      removePer(item) {
        const index = this.addedPers.indexOf(item.PerId);
        if(index == -1) {
          this.deletedPers.push(item.PerId);
        } else {
          this.addedPers.splice(index, 1);
        }
      },
      onBtnPerDelete() {
        this.removePer(this.selectedPer);
        this.selectedPer = null;
        this.showPerDeleteDialog = false;
        this.loadRelatedPers();
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowPerDeleteModal(item) {
        this.selectedPer = item;
        this.showPerDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelPerDelete() {
        this.selectedPer = null;
        this.showPerDeleteDialog = false;
      },
      //#endregion

      //#region Evento actualización empresas
      updateEmpData(data) {
        this.empDataList = data.list;
        this.empDataTotal = data.total;
      },
      //#endregion

      //#region Validar formulario
      validate() {
        this.$refs.form.validate();
        // if (this.perCat == null) {
        //     this.isValid = false;
        //     return;
        // }
        // this.isValid = this.perCat.PerCatNom != null && this.perCat.PerCatNom != '';
      },
      validatePer() {
        if(this.newPer == null) {
          this.isValidNewPer = false;
          return;
        }
        // Si esta añadido no se puede volver a añadir
        if(this.addedPers.indexOf(this.newPer.PerId) != -1) {
          this.isValidNewPer = false;
          return;
        }
        // Si esta borrado se puede volver a añadir
        if(this.deletedPers.indexOf(this.newPer.PerId) != -1) {
          this.isValidNewPer = true;
          return;
        }
        // Comprobamos si ya pertenece a la categoría
        this.perCatHasPerFromApi(this.newPer.PerId).then(data => {
          this.isValidNewPer = !data.any;
        });
      },
      //#endregion
    } 
})
</script>
