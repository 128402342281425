<template>
    <v-autocomplete
        v-model="value"
        cache-items
        dense
        :small-chips="smallChips"
        :deletable-chips="deletableChips"
        hide-selected
        :items="itemsList"
        :search-input.sync="searchItem"
        open-on-clear
        item-value="PmsMsjId"
        item-text="PmsMsjNom"
        outlined
        no-data-text="Introduzca texto a buscar"
        :label="label"
        :readonly="readonly"
        return-object
        ref="PmsMsjFindCtrl"
        :clearable="!readonly"
        :multiple="multiple"
        :menu-props="{'open-on-click': true}"
        @click="clickEvt"
        @click:append="clickAppendEvt"
        @input="inputEvt"
        @change="changeEvt"
        @update:search-input="UpdateSearchInputEvt"
        @blur="blurEvt">
    </v-autocomplete>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null,
    }),
    props: ['value', 'label', 'multiple', 'readonly', 'smallChips', 'deletableChips', 'selectOnReadonly'],
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      value() {
        if (this.value != null) {
          this.loadItems();
        }
      }
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {   
          Action: 'GET_DATA_LIST_PAGINATED',
          PmsMsjNom: valFind,
          NumRegsPag: 35,
          NumPag: 1
        };

        var valorActual = this.value;
        var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/pmsmsj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
          this.itemsList = [];
          result.data.EntsList.forEach(element => {
            var itemObject = {
              PmsMsjId: element.PmsMsjId, 
              PmsMsjNom: element.PmsMsjNom
            }
            this.itemsList.push(itemObject);
          });

          if (valorActual != null) { 
            // if (valorActual.length == null) {
            if (!Array.isArray(valorActual)) {  // es un objeto
                if (valorActual.PmsMsjId != null) {
                    if (!this.itemsList.some(itm=>itm.PmsMsjId === valorActual.PmsMsjId)) {
                    this.itemsList.push(valorActual);
                    }
                }
            }
            else {
                valorActual.forEach(element => {  // es un array
                    if (!this.itemsList.some(itm=>itm.PmsMsjId === element.PmsMsjId)) {
                        this.itemsList.push(element);
                    }
                });
            }
          }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.PmsMsjFindCtrl.isMenuActive) {
            this.$refs.PmsMsjFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.PmsMsjFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.PmsMsjFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      },
      UpdateSearchInputEvt() {
        this.$emit('update:search-input', this.value)
      }
    },
    mounted() {
        this.itemsList = [];
        this.loadItems();
    }
}
</script>